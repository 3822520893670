import React from 'react';
import './Docs.css';

const Docs = () => {
  return (
    <div className="retroRGB">
      <h1>Future Stock</h1>
      <p>And yet you haven't said what I told you to say! How can any of us trust you? Belligerent and numerous. Isn't it true that you have been paid for your testimony? When I was first asked to make a film about my nephew, Hubert Farnsworth, I thought "Why should I?" Then later, Leela made the film. But if I did make it, you can bet there would have been more topless women on motorcycles. Roll film!</p>
      <h2>The Cyber House Rules</h2>
      <p>Fry! Stay back! He's too powerful! Take me to your leader! Who's brave enough to fly into something we all keep calling a death sphere? When will that be? I'm Santa Claus!</p>
      <ul>
        <li>Now, now. Perfectly symmetrical violence never solved anything.</li>
        <li>What's with you kids? Every other day it's food, food, food. Alright, I'll get you some stupid food.</li>
        <li>Who's brave enough to fly into something we all keep calling a death sphere?</li>
        <li>It's toe-tappingly tragic!</li>
      </ul>
      <h3>Anthology of Interest I</h3>
      <p>Oh right. I forgot about the battle. We don't have a brig. Wow, you got that off the Internet? In my day, the Internet was only used to download pornography. I guess because my parents keep telling me to be more ladylike. As though!</p>
      <h4>Proposition Infinity</h4>
      <p>THE BIG BRAIN AM WINNING AGAIN! I AM THE GREETEST! NOW I AM LEAVING EARTH, FOR NO RAISEN! You're going back for the Countess, aren't you? You're going back for the Countess, aren't you?</p>
      <ol>
        <li>You are the last hope of the universe.</li>
        <li>Oh, I think we should just stay friends.</li>
        <li>You can see how I lived before I met you.</li>
        <li>Morbo can't understand his teleprompter because he forgot how you say that letter that's shaped like a man wearing a hat.</li>
      </ol>
      <h5>How Hermes Requisitioned His Groove Back</h5>
      <p>WINDMILLS DO NOT WORK THAT WAY! GOOD NIGHT! You can see how I lived before I met you. You're going back for the Countess, aren't you?</p>
    </div>
  );
};

export default Docs;