import React from 'react';
import './App.css';
import Docs from './Docs';

function App() {
  return (
    <div className="App">
      <Docs />
    </div>
  );
}

export default App;